body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  
}

code {
  font-family: sans-serif;
}
.customLoader{
  background: url('/public/images/loader.gif') center center no-repeat ;
  background-color: transparent; 
  height: 100px;
  width: 100px; 
  background-position: center; 
  background-size: cover; 
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');