// userReg page 1 css

.login-wrapper {
  .progress_image {

    width: 100%;
    float: left;
    height: 18px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    ;

    &.one {
      background-image: url('../images//progress1-15.png');
    }

    &.two {
      background-image: url('../images/progress2-15.png');
    }

    &.three {
      background-image: url('../images/progress3-15.png');
    }
  }

}

.login-container .login-wrapper .form-group a {
  text-align: center;
}

.login-container .login-wrapper .form-group .form-control {
  text-align: left;
}

.m_height {
  min-height: 450px;
  // height: calc(100vh - 10vh);
}

.data_no {
  width: 100%;
  float: left;
  // margin: 8px 0;

}

.btn.log_btn {
  background: #ff6900;
  color: #fff;
  width: 100%;
  float: left;
  padding: 5px;
  // margin-bottom: 80px;
  margin-top: 15px;
  font-size: 16px;
  border-radius: 25px;
  outline: none;

  &:hover {
    opacity: .7;
  }

}

input {

  display: block;
}

button {
  width: 100%;
  float: left;
}

.b_line {
  position: relative;

  .a_line {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    padding-bottom: 10px;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.heading {
  height: 70px;
}

// userReg page 2 css


h3 {

  color: #ff6900;
  border-bottom: 1px solid yellow;
  font-size: 12px;
  width: 100%;
  float: left;
  text-align: left;
  padding-left: 2px;
  text-transform: uppercase;
  padding-bottom: 4px;
  letter-spacing: 1px;
  font-weight: 600;
}

.s_gap {
  width: 100%;
  float: left;
  margin: 3px 0;
}

.login-container .login-wrapper .form-group label {
  font-weight: 500;
}

// userReg page 3 css