.lan-menu-button {
  width: 4rem;
}


.shawn-btn {
  background-color: transparent;
  color: white;
  border-color: #fff;
  display: block;
  margin: auto;
  padding: 10px 20px;
  outline: none;
  border: 2px solid white;
  cursor: pointer;
  background: none;
  border-radius: 0.5rem;
}


.shawn-btn:hover {
  transform: scale(1.1);
  color: white;
  background-color: transparent;
  border-color: #fff;

}

.shawn-btn:active {
  color: white;
  background-color: transparent;
  border-color: #fff;
}

.shawn-btn:focus {
  color: white;
  background-color: transparent;
  border-color: #fff;
  outline: none;
}

.last-anchor {
  text-align: center !important;
}

.navbar-brand {
  width: 80px;
  // height: 80px;
  float: left;

  img {
    display: inline-block;
    width: 100%;
  }
}

button.new_btn_style {
  width: 100% !important;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #008bea;
}

button.new_btn_style:focus {
  outline: none;
}

.myElFoooter {
  position: sticky;
  bottom: 0;
}

.login-bg-img {
  background-image: url('../images/login_background-14.png');
  width: 100%;
  height: calc(100vh - 120px);
  float: left;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  >img {
    visibility: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: yellow;
    width: 100%;
    height: 25px;
    z-index: 1;
    opacity: 0.8;
  }
}

.login-container {
  border-radius: 40px;
  padding: 40px 20px;
  opacity: 0.85;
  max-width: 400px;

  width: 300px;
  height: 100%;
  position: relative;

  .yellow_back {
    width: 100%;
    float: left;
    background: #db6c10;
    padding: 2px 0;

    p {

      color: #fff;
    }
  }

  @media (max-width: 767px) {
    max-width: inherit;
    width: 100%;
  }

  .login-wrapper {
    border-radius: 20px;
    background: #fff;
    padding: 0 15px;
    -moz-box-shadow: 5px 6px 8px #333;
    -webkit-box-shadow: 5px 6px 8px #333;
    box-shadow: 5px 6px 8px #333;
    margin-top: 26px;
    position: relative;

    @media (max-width: 767px) {
      max-width: inherit;
    }

    .form-group {
      margin-bottom: 0.5rem;
      font-size: 14px;

      &.form1 {
        text-align: left;
      }

      .reset {
        display: block;
        transition: all .5s ease;
        color: #008bea;

        &:hover {
          color: green;
        }
      }

      a {
        text-decoration: none;
        text-align: left;
      }

      label {
        color: #5b5b5b;
        font-size: 14px;
        margin-bottom: 4px;
        font-family: Arial;
        text-align: left;
      }

      .form-control {
        color: #000;
        padding-left: 15px;
        width: 100% !important;
        min-height: 36px;
      }

      .captcha-txt {
        margin-top: 10px;

        .refresh {
          margin-left: 20px;
          background: red;
          color: #fff;
          padding: 4px 5px;
          border-radius: 100%;
          cursor: pointer;
        }
      }

      &.virtual-kb {
        label {
          cursor: pointer;
        }

        @media (max-width:991px) {
          display: none;
        }

        .keyboard {
          padding-right: 5px;
        }

        a {
          font-size: 12px;
        }

        #kbd {
          display: none;
        }
      }

      &.register {
        color: #008bea;
      }

      &.form1 {
        border-bottom: 0;

        .no_style {
          background: none !important;
        }
      }
    }

    .log-btn {
      background: #db6c10;
      color: #fff;
      width: 100%;
      float: left;
      padding: 5px;
      margin-bottom: 20px;
      margin-top: 6px;
      font-size: 16px;
      border-radius: 25px;
      outline: none;

      &:hover {
        opacity: .7;
      }
    }
  }
}

.keyboard1 {
  width: 80%;
  background-color: white;
}

.simple-keyboard.hg-theme-default {
  background-color: #E8E8E8;
  border-radius: 5px;
}

.simple-keyboard .hg-row .hg-button:not(:last-child) {
  margin-right: 5px;
}

form {
  border: 0;
}

.hg-button {
  font-weight: 500;
}

.container {
  max-width: 1200px;

  @media(max-width: 767px) {
    padding: 0;
  }
}

// FAQ style
.accordion-section {
  max-width: 900px;
  margin: 0 auto 30px;
}

.about_section {
  padding: 10px 0;
  max-width: 900px;
  margin: 0 auto 30px;
  // overflow: scroll;
  height: calc(100vh - 200px);
}

.border.new_section {
  overflow: scroll;
  height: calc(100vh - 130px);
}

.wrapper-main {
  position: absolute;
  top: 0px;
}


.login-logo-img {
  margin-top: 2.5rem;
}

.logo-wrapper-img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 200px;
  min-width: 200px;
  max-width: 200px;
}

.login-card {
  border-radius: 14px;
}


/* Responsive start */
@media only screen and (max-width: 1170px) {
  .m_height {
    min-height: 490px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .top-header {
    padding: 20px 0;
  }

  .top-header .navbar .navbar-brand {
    margin-left: 10px !important;
  }

  .login-wrapper .progress_image {
    height: 60px !important;
    margin-bottom: 18px !important;
  }

  .m_height {
    min-height: 510px !important;
  }

  .login-bg-img {
    height: calc(100vh - 144px) !important;
    // overflow: scroll;
  }

  .login-container {
    max-width: inherit !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 10px);
    height: auto;
  }
}



@media only screen and (max-width: 823px) and (min-width : 813px) {
  .login-container {
    margin-top: 199px !important;
  }

  .login-bg-img {
    // height: calc(100vh - 144px) !important;
    overflow: scroll;
  }

  button.new_btn_style {
    width: 100% !important;
  }
}

@media only screen and (max-width: 812px) and (min-width : 810px) {
  .login-container {
    margin-top: 217px !important;
  }

  button.new_btn_style {
    width: 100% !important;
  }

  .login-bg-img {
    // height: calc(100vh - 144px) !important;
    overflow: scroll;
  }

  // .border.new_section{
  //   overflow:scroll;
  //   height: calc(100vh - 130px);
  // }
}

@media only screen and (max-width:783px) and (min-width:769px) {
  .login-container {
    width: 100% !important;
    margin-top: 57px !important;
  }

  button.new_btn_style {
    width: 100% !important;
  }

  .login-bg-img {
    // height: calc(100vh - 144px) !important;
    overflow: scroll;
  }
}

@media only screen and (max-width: 767px) {
  .top-header {
    padding: 12px 0;
  }

  .navbar-brand {
    width: 75px;
    height: 75px;
  }

  button.new_btn_style {
    width: 100% !important;
  }

  .menu_header {
    font-size: 20px !important;
  }

  .login-bg-img {
    background-image: none;
  }

  .login-container {
    width: 100% !important;
    margin-top: 57px !important;
  }

  .login-wrapper .progress_image {
    height: 50px !important;
    margin-bottom: 0px !important;
  }

  .navbar-collapse {
    margin-top: 12px !important;
  }
}

@media only screen and (max-width: 736px) and (min-width : 730px) {
  .login-container {
    margin-top: 164px !important;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_left p {
    font-size: 12px !important;
  }

  ul li .tag_right .left_right p {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 667px) and (min-width: 666px) {
  .login-container {
    margin-top: 213px !important;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_left p {
    font-size: 12px !important;
  }

  ul li .tag_right .left_right p {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 640px) and (min-width: 639px) {
  .login-container {
    margin-top: 213px !important;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_left p {
    font-size: 11px !important;
  }

  ul li .tag_right .left_right p {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 600px) {
  .login-wrapper .progress_image {
    height: 38px !important;
  }

  .login-container {
    margin-top: 88px !important;
  }
}

@media only screen and (max-width: 568px) and (min-width: 560px) {
  .login-container {
    margin-top: 238px !important;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_left p {
    font-size: 12px !important;
  }

  ul li .tag_right .left_right p {
    font-size: 12px !important;
    padding-left: 10px !important;
  }
}

@media only screen and (max-width: 414px) and (min-width: 410px) {
  .login-container {
    margin-top: 34px !important;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_right .left_right p {
    padding-left: 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  .login-container {
    // margin-top: 57px !important;
    margin-top: 100px !important;
    height: 100vh;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_right .left_right p {
    padding-left: 10px !important;
  }

  ul li .tag_left p {
    font-size: 12px !important;
  }

  ul li .tag_right .left_right {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 374px) {
  .login-container {
    margin-top: 92px !important;
  }

  .login-wrapper .progress_image {
    display: none;
  }

  ul li .tag_left {
    padding-left: 4px !important;
  }

  ul li .tag_right .left_right p {
    padding-left: 4px !important;
    padding-right: 0px !important;
  }

  ul li .tag_left p {
    font-size: 12px !important;
  }

  ul li .tag_right .left_right {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 320px) and (min-width: 319px) {
  .login-container {
    margin-top: 95px !important;
    height: auto !important;
  }

  ul li .tag_right {
    margin-top: -12px !important;
  }

  ul li .tag_left {
    padding-left: 10px !important;
  }

  ul li .tag_left p {
    font-size: 12px !important;
  }

  ul li .tag_right .left_right p {
    font-size: 12px !important;
  }

  ul li .tag_left {
    padding-left: 4px !important;
    // margin-top: -10px !important;
  }

  ul li .tag_right .left_right p {
    padding-left: 4px !important;
    padding-right: 0px !important;
  }
}