.tblrow ul{
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.tblrow ul li{
display: inline-block;
width: 50%;
float: left;
text-align: center;

}
.tblrow ul li a{
    color: black;
}
.tblrow ul li a:hover{
    opacity: .8;
    color: green;
}
.btn.btn_modal{
    padding: 0;
    font-size: 14px;
}
.modal-header{
    padding: 10px;
    
}
 h5.modal-title{
    font-size: 14px;
    font-weight: 600;
    
}
.modal-footer{
    padding: 10px;
}
.modal-content{
    width: 90%;
}