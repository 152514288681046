.spinner {
    width: 100%;
    height: 100%;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fp-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    background: #F5F5F5;
    background: #F5F5F5ad;
  }
  
  .fp-container .fp-loader{
    top:50%;
    left :50%;
    z-index: 1000;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .customLoader{
    background: url('/public/images/loader.gif') center center no-repeat ;
    background-color: transparent; 
    height: 100px;
    width: 100px; 
    background-position: center; 
    background-size: cover; 
  }
/*   
   .loader-wrapper-shawn {
    display: inline-block;
    width: 120px;
    height: 120px;
  }
  
  .loader-shawn {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 8px solid white;
    border-top-color: #2e5f8c;
    border-bottom-color: #2e5f8c;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
  }
  
  .loader-inner-shawn {
    border-top-color: #d57f1c;
    border-bottom-color: #d57f1c;
    animation-duration: 2.5s;
  }
  
  @keyframes rotate {
    0% {
      transform: scale(1) rotate(360deg);
    }
    50% {
      transform: scale(0.8) rotate(-360deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
   */