.active {
  .orange-btn {
    background-color: #d57f1c !important;
    color: #fff !important;
  }
}

header {
  width: 100%;

  .top-header {
    .navbar {
      border-bottom: 0;

      @media (max-width: 991px) {
        padding: 0;

        .navbar-toggler {
          margin: 15px;

          // updated
          display: contents;

          @media (max-width: 600px) {
            margin: 6px;
          }
        }
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            @media (max-width: 991px) {
              border-top: 1px solid green;
            }

            .nav-link {
              color: rgba(99, 99, 99, 1);
              font-weight: 500;
              font-size: 13px;

              // text-transform: uppercase;
              @media (max-width: 991px) {
                font-size: 13px;
                padding: 5px 20px;
                text-align: left;
                letter-spacing: 1px;
              }

              .icon {
                color: rgba(99, 99, 99, 1);
                font-weight: 700;
                padding-right: 5px;
                font-size: 18px;

                &:hover {
                  color: #d57f1c !important;
                }
              }

              &:hover {
                color: #d57f1c !important;
              }
            }
          }
        }
      }
    }
  }

  .bottom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //  background: #a40307;
    color: #fff;
    z-index: 2;
    position: relative;
    padding: 6px 15px;
    box-shadow: 0px 3px 5px 0px #333;

    @media (max-width: 767px) {
      display: block;
    }

    .user-name {
      width: 20%;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .right-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 80%;

      @media (max-width: 767px) {
        display: block;
        width: 100%;
      }

      .date-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;

        @media (max-width: 767px) {
          justify-content: flex-start;
          margin-bottom: 10px;
          padding-left: 0;
        }

        li {
          list-style: none;
          padding-right: 8px;
          margin-left: 8px;
          font-size: 14px;
          border-right: 1px solid #fff;

          @media (max-width: 767px) {
            font-size: 10px;

            &:last-child {
              border-right: none;
            }
          }

          &.demo {
            font-weight: 600;
            font-size: 14px;
            padding-right: 8px;

            @media (max-width: 767px) {
              font-size: 12px;
            }
          }

          &.dt-wrp {
            padding-right: 8px;

            @media (max-width: 767px) {
              padding-right: 0;
            }
          }

          a {
            color: #fff;
            position: relative;
            font-size: 16px;
          }

          &.notification {
            background: transparent;
          }
        }
      }

      .noti-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 12%;
        margin: 0;
        padding-left: 0px;

        @media (min-width: 1025px) {
          width: 12%;
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          width: 22%;
          padding-left: 10px;
        }

        @media (max-width: 767px) {
          width: auto !important;
          text-align: right;
          justify-content: flex-end;
          padding-left: 0;
        }

        li {
          flex-basis: 25%;
          text-align: center;

          @media (max-width: 1024px) {
            flex-basis: auto;
          }

          @media (max-width: 767px) {
            flex-basis: 10%;
            text-align: right;
          }

          &.notifica {
            a {
              display: inline-block;
            }
          }

          a {
            color: #fff;
            position: relative;
            text-decoration: none;
            vertical-align: middle;

            .notification {
              background: none;
              box-shadow: none;
              margin-right: 0;
              top: 0;
              position: relative;
              padding: 0;

              &:after {
                position: absolute;
                padding: 0;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #fff;
                color: #a40307;
                right: -7px;
                top: -3px;
                text-align: center;
              }
            }
          }

          .SelectTheme {
            right: 55px;
            top: 13%;
            outline: none;
            border: none;
            background: transparent;

            @media (max-width: 767px) {
              right: 0px;
              position: absolute !important;
            }

            &.fa-fill {
              &:before {
                content: none;
              }
            }

            button {
              min-width: 15px !important;
            }
          }
        }

        .dropdown-menu {
          &.Notificationthem {
            &.show {
              width: 240px;
              overflow-y: auto;
              height: 450px;
              top: 9px !important;
              left: 0px !important;
              transform: translate3d(-108px, 24px, 0px) !important;
            }
          }
        }
      }
    }
  }
}

ul {
  li {
    list-style: none;
  }
}

.card-header {
  &.card-header-chng {
    max-width: 800px;
    margin: 0 auto;
    background: rgba(233, 224, 214, 1);
    border-radius: 15px;
    box-shadow: 5px 6px 8px #ccc;

    .card-row {
      align-items: center;
      padding: 5px 30px;

      @media (max-width: 767px) {
        padding: 8px 15px;
      }

      .headerBox {
        display: flex;
        align-items: center;
        margin-right: 5px;
        font-weight: 600;

        .icon {
          font-size: 3rem;
        }
      }

      .graph-wrap {
        display: flex;
        align-items: center;

        .target {
          margin-left: 15px;

          .div1,
          .div2,
          .div3 {
            width: 15px;
          }

          .lbl,
          .lbl2,
          .lbl2 {
            font-size: 12px;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }

      .setLimit {
        cursor: default;

        .nav-link {
          color: rgba(51, 102, 153, 1);
          font-size: 14px;
          display: inline-block;
          cursor: pointer;
          font-weight: 600;
        }
      }

      >div {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

#page-content-wrapper {
  .bdr-btm {
    position: relative;

    &:after {
      content: '';
      max-width: 1800px;
      height: 8px;
      // background: rgba(207, 48, 52, 1);
      position: absolute;
      left: -59px;
      right: -30px;
      margin-top: 20px;
      box-shadow: 5px 6px 8px #ccc;

      @media (max-width: 991px) and (min-width: 768px) {
        left: -32px;
      }
    }
  }

  .nav-pills {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }
}

// .MuiPaper-root-308{
//   top: 122.4062px !important;
// }
div[class^="MuiPaper-root"] {
  top: 122.4062px !important;
  padding: 5px;

  // left: 1199px !important;
  @media (max-width: 767px) {
    top: 182.4062px !important;
  }
}

.acc-num-select {
  width: 34%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 8px;
  margin-top: 34px;

  @media (max-width: 1024px) and (min-width: 768px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.account-detail-wrp {
  border-radius: 6px;
}

@media (max-width: 767px) {
  .border-right {
    border-right: 1px solid #ccc;
  }
}

.cust-detail {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;

  >label {
    flex-basis: 40%;
  }

  >.tbldesign {
    flex-basis: 60%;
  }
}

.acc-add {
  padding: 5px 10px;

  >label {
    flex-basis: 40%;
  }

  >.tbldesign {
    flex-basis: 60%;
  }
}

.br-detail {
  >label {
    flex-basis: 40%;
  }

  >.tbldesign {
    flex-basis: 60%;
  }
}

.notespopup {
  position: absolute;
  top: 100%;
  right: 5px;
  background: #fff;
  box-shadow: 0px 3px 5px 0px #333;
  width: 250px;
  padding: 10px;

  .notes-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #333;

    button {
      outline: none;
      border: none;
      background: transparent;
      color: #a40307;
    }

    p {
      margin-bottom: 0;
    }
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    button {
      outline: none;
      border: none;
      background: transparent;
      // color: #a40307;
      // border: 1px solid #a40307;
      padding: 3px 6px;
      transition: all 0.4s ease;
      font-size: 12px;
      border-radius: 5px;

      &:hover {
        box-shadow: 0px 3px 4px 0px #333;
      }
    }
  }

  textarea {
    width: 100%;
  }

  .res-data {
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px #8CFBDE;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CFBDE;
      ;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #8CFBDE;
      ;
    }

    .notes-data {
      color: #333;
      padding: 5px 0 5px 5px;
      border-top: 1px solid #333;
      list-style: none;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 5px;

      &:last-child {
        border-bottom: 0;
      }

      .btn-wrp {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          outline: none;
          border: none;
          background: transparent;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      span {
        word-break: break-all;
      }
    }
  }
}

#page-content-wrapper {
  .account-form-det {
    .bdr-btm {
      &:after {
        margin-top: 12px;
      }
    }
  }
}

#mini_statement {
  #render_me {
    >div {
      overflow-x: auto;
    }
  }
}

@media (min-width: 768px) {
  body {
    overflow: hidden;
    height: 100%;
    max-height: 100%;
  }

  #page-content-wrapper {
    overflow-y: auto;
    height: calc(100vh - 140px);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px #8CFBDE;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8CFBDE;
      ;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #8CFBDE;
      ;
    }
  }
}