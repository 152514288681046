.yellow_line_user{
    width: 100%;
    float: left;
    height: 50px;
    font-size: 14px;
    background: #ffff;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    color: navy;
}
.yellow_line_user ul{
    width: 100%;
    float: left;
    padding: 0;
}
.yellow_line_user ul li{
    width: 100%;
    float: left;
    padding: 4px 0
}
ul li .tag_left {
    width: 50%;
    float: left;
    text-align: left;
    padding-left: 20px;
    
}
ul li .tag_left p{
    text-align: left;
    text-transform: capitalize;
    letter-spacing: 1px;
}

ul li .tag_right{
    width: 50%;
    float:right;
    text-align: right;
}
ul li .tag_right .left_right{
    width: 88%;
    float: left;
    
    /* text-align: right; */
}
ul li .tag_right .left_right p{
    text-align: right;
    padding-right: 15px;
    letter-spacing: 1px;
}
ul li .tag_right .right_right{
    width: 12%;
    float: right;
    text-align: left;
}
ul li .tag_right .right_right a{
    text-align: left;
    padding-left:15px ;
}
.dash_acc{
    width: 100%;
    float: left;
}
.dash_acc ul{
    width: 100%;
    float: left;
}
.dash_acc ul li{
    display: inline-block;
}
.wlc{
    margin-right : 0rem;
    color: #ffff;
    font-size: 13px;
    font-weight: 500px !important;
}

.wlc:hover{
    color: #ffff;
}
.image-head{
height: 100px;
}
.acord_btn:focus,
.acord_btn:hover {
    background-color: #d57f1c !important;
    color: #fff !important;
}
.firsthit{
    background-color: #d57f1c !important;
    color: #fff !important;

}
.customAccordi {

    overflow: scroll;
    overflow-x: hidden;
    height: 460px;
}
.switchClass {
    overflow-y: auto;
    height: calc(100vh - 120px);
    /* overflow: scroll;
    overflow-x: hidden;
    height: 100vh; 
    max-height: 460px;
  min-height: 100vh;  */
}
.customAccordi::-webkit-scrollbar{
    display: none;
}
.switchClass::-webkit-scrollbar{
    display: none;
}
.customDisplay {

    overflow: scroll;
    overflow-x: hidden;
    height: 700px;
}
.customDisplay::-webkit-scrollbar{
    display: none;
}


@media(min-width:1023px) {
    .main_Dash {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
@media(min-width:1023px) {
    .main_Dash {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}